import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class ApiService {
  public default = {
    list: {
      size: 10,
      page: 0,
      paged: true,
      offset: 0,
    },
  };
  private apis = {
    staffs: {
      enpoint: '/staffs',
      list: '/filter',
      create: '',
      get: '/',
      resetPassword: '/reset-password',
      export: '/export',
      edit: '/reset-password/',
      search: '/search',
    },
    shops: {
      enpoint: '/shops',
      list: '',
      path: '',
      get: '/',
      import: '/import',
    },
    product: {
      enpoint: '/products',
      list: '',
      create: '',
      get: '/',
      disable: '/',
      path: '',
      search: '',
      put: '/',
      import: '/uploadFile',
    },
    qrcodes: {
      enpoint: '/qrcodes',
      list: '',
      get: '/',
      import: '/import',
      delete: '',
      put: '',
    },
    consumers: {
      enpoint: '/consumers',
      list: '',
      edit: '',
      get: '/',
      export: '/export',
      put: '/',
      report: '/report/lead/',
      import: '/import',
    },
    images: {
      upload: '/images/uploadFile',
      uploadGift: '/gifts/uploadFile',
      path: '/uploads/',
    },
    dashboard: {
      enpoint: '/dashboard',
      get: '',
      import: '/import',
    },
    purchasedRanges: {
      enpoint: '/purchasedRanges',
      export: '/export',
      list: '',
      put: '/',
      delete: '/',
      import: '/import',
    },
    persons: {
      enpoint: '/persons',
      export: '/export',
      list: '',
      put: '/',
      path: '/updated/status',
      delete: '/',
      import: '/import',
      get: '/',
    },
    materials: {
      enpoint: '/materials',
      export: '/export',
      list: '',
      create: '',
      put: '/',
      path: '/updated/status',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '/',
    },
    fabrics: {
      enpoint: '/fabrics',
      export: '/export',
      create: '',
      list: '',
      put: '/',
      path: '/',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '/',
    },
    warehouse: {
      enpoint: '/warehouses',
      export: '/export',
      list: '',
      put: '/',
      path: '/updated/status',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '/',
      create: '',
    },
    productDefective: {
      enpoint: '/product-defectives',
      export: '/export',
      list: '',
      put: '/',
      path: '/updated/status',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '/',
      create: '',
    },
    warehouseIncome: {
      enpoint: '/incomes',
      export: '/export',
      list: '',
      put: '/',
      path: '/status/',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '/',
      create: '',
    },
    warehouseOutcome: {
      enpoint: '/outcomes',
      export: '/export',
      list: '',
      put: '/',
      path: '/status/',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '/',
      create: '',
    },
    productStock: {
      enpoint: '/product-stocks',
      export: '/export',
      list: '',
      put: '',
      path: '/',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '/',
      create: '',
    },
    manufactureCommand: {
      enpoint: '/manufacture-commands',
      export: '/export',
      list: '',
      put: '/',
      path: '/',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '/',
      create: '',
      statistical: 'manufacture-commands/statistical',
    },
    suppliesPrice: {
      enpoint: '/supplies-prices',
      export: '/export',
      list: '',
      put: '/',
      path: '/',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '/',
      create: '',
    },
    customer: {
      enpoint: '/customers',
      export: '/export',
      list: '',
      put: '',
      path: '/',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '',
      create: '',
      post: '/',
    },
    partnerManufacture: {
      enpoint: '/partner-manufactures',
      export: '/export',
      list: '',
      put: '',
      path: '/',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '',
      create: '',
      post: '/',
    },
    partnerTransport: {
      enpoint: '/partner-transports',
      export: '/export',
      list: '',
      put: '',
      path: '/',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '',
      create: '',
      post: '/',
    },
    agency: {
      enpoint: '/agencies',
      export: '/export',
      list: '',
      put: '',
      path: '/',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '',
      create: '',
      post: '/',
    },
    partnerSupplier: {
      enpoint: '/suppliers',
      export: '/export',
      list: '',
      put: '',
      path: '/',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '',
      create: '',
      post: '/',
    },
    purchaseOrders: {
      enpoint: '/purchase-orders',
      export: '/export',
      list: '',
      put: '/',
      path: '/',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '',
      create: '',
      post: '/',
      statistical: '/purchase-orders/statistical',
    },
    purchaseOrderQuotas: {
      enpoint: '/purchase-order-quotas',
      export: '/export',
      list: '',
      put: '/',
      path: '/',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '',
      create: '',
      post: '/',
    },
    boms: {
      enpoint: '/boms',
      export: '/export',
      list: '',
      put: '/',
      path: '/',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '',
      create: '/',
      post: '/',
    },

    productDesign: {
      enpoint: '/product-designs',
      export: '/export',
      list: '',
      put: '/',
      path: '/',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '',
      create: '',
      post: '/',
    },
    supplierQuota: {
      enpoint: '/supplier-quotas',
      export: '/export',
      list: '',
      put: '/',
      path: '/',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '/apply-price',
      create: '',
      post: '/',
    },
    suppliesBuyPlan: {
      enpoint: '/supplies-buy-plans',
      export: '/export',
      list: '',
      put: '/',
      path: '/',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '',
      create: '',
      post: '/',
    },
    suppliesBuyItem: {
      enpoint: '/supplies-buy-plan-details',
      export: '/export',
      list: '',
      put: '/',
      path: '/',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '/price/',
      create: '',
      post: '/',
    },
    deliveryRequests: {
      enpoint: '/delivery-requests',
      export: '/export',
      list: '',
      put: '/',
      path: '/',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '/price/',
      create: '',
      post: '/',
    },
    warehouseFormRequestDetail: {
      enpoint: '/warehouse-form-request-details',
      export: '/export',
      list: '',
      put: '/',
      path: '/status/',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '/price/',
      create: '',
      post: '/',
    },
    deliveries: {
      enpoint: '/deliveries',
      export: '/export',
      list: '',
      put: '/',
      path: '/',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '/price/',
      create: '',
      post: '/',
    },
    manufactureCommands: {
      enpoint: '/manufacture-commands',
      export: '/export',
      list: '',
      put: '/',
      path: '/status/',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '/price/',
      create: '',
      post: '/',
      pathNPL: '/outcome/supplies/',
    },
    manufacturePlans: {
      enpoint: '/manufacture-plans',
      export: '/export',
      list: '',
      put: '/',
      path: '/status/',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '/price/',
      create: '',
      post: '/',
    },
    manufactureBoms: {
      enpoint: '/manufacture-boms',
      export: '/export',
      list: '',
      put: '/',
      path: '/status/',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '/price/',
      create: '',
      post: '/',
    },
    manufactureFormRequests: {
      enpoint: '/manufacture-form-requests',
      export: '/export',
      list: '',
      put: '/',
      path: '/status/',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '/price/',
      create: '',
      post: '/',
    },
    warehouseFormRequests: {
      enpoint: '/warehouse-form-requests',
      export: '/export',
      list: '',
      put: '/',
      path: '/status/',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '/price/',
      create: '',
      post: '/',
    },
    qualityFormRequests: {
      enpoint: '/quality-form-requests',
      export: '/export',
      list: '',
      put: '/',
      path: '/status/',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '/price/',
      create: '',
      post: '/',
    },
    logoPrintingFormRequests: {
      enpoint: '/logo-printing-form-requests',
      export: '/export',
      list: '',
      put: '/',
      path: '/status/',
      delete: '/',
      import: '/import',
      get: '/',
      patch: '/price/',
      create: '',
      post: '/',
    },
  };
  constructor(private http: HttpClient) {}
  list(object, data, notChangeUrl?) {
    var query: any = this.objectToQueryString(data);
    const header = new HttpHeaders();
    if (!notChangeUrl) window.history.pushState('', '', window.location.href.split('?')[0] + '?' + query);
    return this.http.get<any>(
      `${environment.apiUrl}${this.apis[object].enpoint}${this.apis[object].list}` + '?' + query
    );
  }
  export(object, data) {
    var query = this.objectToQueryString(data);
    return this.http.get<any>(
      `${environment.apiUrl}${this.apis[object].enpoint}${this.apis[object].export}` + '?' + query,
      { responseType: 'blob' as 'json' }
    );
  }
  exportById(object: string, id: string) {
    return this.http.get<any>(`${environment.apiUrl}${this.apis[object].enpoint}${this.apis[object].export}/${id}`, {
      responseType: 'blob' as 'json',
    });
  }
  listSelector(object, data) {
    var query = this.objectToQueryString(data);
    return this.http.get<any>(
      `${environment.apiUrl}${this.apis[object].enpoint}${this.apis[object].list}` + '?' + query
    );
  }
  get(object, id) {
    return this.http.get<any>(
      `${environment.apiUrl}${this.apis[object].enpoint}${this.apis[object].get}` + (id ? id : '')
    );
  }
  getPathCustomId(object, path, id) {
    return this.http.get<any>(
      `${environment.apiUrl}${this.apis[object].enpoint}${this.apis[object][path]}` + (id ? id : '')
    );
  }
  getPath(object, path, paramData) {
    var query = '';
    if (paramData) {
      var query = this.objectToQueryString(paramData);
    }
    return this.http.get<any>(`${environment.apiUrl}${this.apis[object].enpoint}${path}` + (query ? '?' + query : ''));
  }
  post(object, method, data, paramData?) {
    var query = '';
    if (paramData) {
      var query = this.objectToQueryString(paramData);
    }
    return this.http.post<any>(
      `${environment.apiUrl}${this.apis[object].enpoint}${this.apis[object][method]}` + (query ? '?' + query : ''),
      data
    );
  }
  deleteBom(object, bomId, partId, data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: data,
    };
    return this.http.delete<any>(`${environment.apiUrl}${this.apis[object].enpoint}/${bomId}/part/${partId}`, options);
  }
  delete(object, method, data, paramData?) {
    var query = '';
    if (paramData) {
      var query = this.objectToQueryString(paramData);
    }
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: data,
    };
    return this.http.delete<any>(
      `${environment.apiUrl}${this.apis[object].enpoint}${this.apis[object][method]}` + (query ? '?' + query : ''),
      options
    );
  }
  deleteById(object, method, id) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.delete<any>(
      `${environment.apiUrl}${this.apis[object].enpoint}${this.apis[object][method]}` + id,
      options
    );
  }
  put(object, data, paramData?) {
    if (paramData) {
      return this.http.put<any>(
        `${environment.apiUrl}${this.apis[object].enpoint}${this.apis[object].put}` + (paramData ? paramData : ''),
        data
      );
    }
    return this.http.put<any>(`${environment.apiUrl}${this.apis[object].enpoint}${this.apis[object].put}`, data);
  }
  putMethodNewVersion(object, data, id?) {
    return this.http.put<any>(
      `${environment.apiUrl}${this.apis[object].enpoint}${this.apis[object].put}${id ?? ''}`,
      data
    );
  }
  patchMethod(object, method, id, paramData, data) {
    if (paramData) {
      var query = this.objectToQueryString(paramData);
    }
    return this.http.patch<any>(
      `${environment.apiUrl}${this.apis[object].enpoint}${this.apis[object][method]}` +
        (id ? '\\' + id : '') +
        (query ? '?' + query : ''),
      data
    );
  }
  create(object, data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}${this.apis[object].enpoint}${this.apis[object].create}`, data);
  }
  patch(object, id, paramData, data) {
    if (paramData) {
      var query = this.objectToQueryString(paramData);
    }
    return this.http.patch<any>(
      `${environment.apiUrl}${this.apis[object].enpoint}${this.apis[object].path}` +
        (id ?? '') +
        (query ? '?' + query : ''),
      data
    );
  }
  import(object, data) {
    return this.http.post<any>(`${environment.apiUrl}${this.apis[object].enpoint}${this.apis[object].import}`, data);
  }
  getUploadUrl() {
    return `${environment.apiUrl}${this.apis['images'].upload}`;
  }
  uploadFileImage(file: any) {
    var fd = new FormData();
    fd.append('file', file);
    return this.http.post<any>(`${environment.apiUrl}${this.apis['images'].upload}`, fd);
  }
  getGiftUploadUrl() {
    return `${environment.apiUrl}${this.apis['images'].uploadGift}`;
  }
  getImage(name) {
    return `${environment.apiUrl}${this.apis['images'].path}` + name;
  }
  getImportUrl(object) {
    return `${environment.apiUrl}${this.apis[object].enpoint}${this.apis[object].import}`;
  }
  openImportDialog(object) {}
  filterStaticData(query, data, field) {
    let filtered: any[] = [];
    for (let i = 0; i < data.length; i++) {
      let item = data[i];
      if (query == undefined || item[field].toLowerCase().indexOf(query.toLowerCase()) !== -1) {
        filtered.push(item);
      }
    }
    return filtered;
  }
  objectToQueryString(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p) && obj[p] !== undefined && obj[p] !== null && obj[p] !== '') {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    return str.join('&');
  }
  getDataFromUrl(query, params) {
    for (var p in query)
      if (
        query.hasOwnProperty(p) &&
        params.hasOwnProperty(p) &&
        params[p] !== undefined &&
        params[p] !== null &&
        params[p] !== 'null' &&
        params[p] !== ''
      ) {
        query[p] =
          params[p] === 'true'
            ? true
            : params[p] === 'false'
            ? false
            : !isNaN(parseFloat(params[p])) && params[p].indexOf('/') === false
            ? parseFloat(params[p])
            : params[p];
      }
  }
  getCity() {
    return this.http
      .get<any>('assets/data/city.json')
      .toPromise()
      .then((data) => {
        var results = [];
        for (var p in data)
          if (data.hasOwnProperty(p) && data[p] != undefined) {
            results.push(data[p]);
          }
        return results;
      });
  }
  getDates(rangeDates) {
    var dateFrom = undefined,
      dateTo = undefined;
    if (rangeDates && rangeDates[0]) {
      dateFrom = this.formatDate(rangeDates[0]);
    } else {
      dateFrom = undefined;
    }
    if (rangeDates && rangeDates[1]) {
      dateTo = this.formatDate(rangeDates[1]);
    }
    // else {
    // if (rangeDates && rangeDates[0]) {
    //     dateTo = this.formatDate(new Date(rangeDates[0].setDate(rangeDates[0].getDate() + 1)));
    // } else {
    //     dateTo = undefined;
    // }
    // }
    if (dateFrom != undefined && dateFrom == dateTo) {
      dateTo = this.formatDate(new Date(rangeDates[0].setDate(rangeDates[0].getDate() + 1)));
    }
    return [dateFrom, dateTo];
  }
  formatDate(date) {
    var month = String(date.getMonth() + 1).padStart(2, '0');
    var day = String(date.getDate()).padStart(2, '0');
    var year = date.getFullYear();
    return year + '/' + month + '/' + day;
  }
  report(object, data, query) {
    return this.http.get<any>(
      `${environment.apiUrl}${this.apis[object].enpoint}${this.apis[object].report}` + data + '?' + query
    );
  }

  // http://knfast-api.gmtsoftware.vn/consumers/report/lead/month?year=2023
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // Lỗi từ phía client
      console.error('Client-side error:', error.error.message);
    } else {
      // Lỗi từ phía server
      console.error(`Server-side error: ${error.status} - ${error.message}`);
    }
    // Trả về một Observable lỗi
    return throwError(() => new Error('Có lỗi xảy ra trong quá trình gọi API, vui lòng thử lại sau.'));
  }
}

