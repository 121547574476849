import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { ClassToggleService, HeaderComponent } from '@coreui/angular';
import { ApiService } from '../../../services/api.service';
import { AuthenticationService } from '../../../services/auth.service';
import { LoadingService } from '../../../services/loading.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
})
export class DefaultHeaderComponent extends HeaderComponent {
  @Input() sidebarId: string = 'sidebar';
  public isLoading = null;
  public newMessages = new Array(4);
  public newTasks = new Array(5);
  public newNotifications = new Array(5);
  flag = 1;
  id;
  user;
  flags = ['/assets/img/flags/flag-vn.png', '/assets/img/flags/flag-en.png'];
  constructor(
    private classToggler: ClassToggleService,
    private router: Router,
    private _authService: AuthenticationService,
    public apiService: ApiService,
    private loadingService: LoadingService
  ) {
    super();
  }
  ngOnInit() {
    this.id = this._authService.getCurrentUser()['idStaff'];
    if (this.id) {
      this.loadingService.setLoading(true);
      this.apiService.get('staffs', this.id).subscribe((data) => {
        this.loadingService.setLoading(false);
        this.user = data;
      });
    }
  }
  logout() {
    this._authService.logout();
    this.router.navigate(['/auth/login']);
  }
}

